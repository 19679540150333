<template>
  <v-row justify="center">
    <v-dialog :value="open" @click:outside="handleClose" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <template v-if="editedItem.id">Edit Template</template>
          <template v-else>Create Template</template>
        </v-card-title>
        <v-card-text
          >Please, if you want to set candidate`s name use $ symbol in template`s text</v-card-text
        >
        <v-card-text>
          <v-container>
            <v-text-field v-model.trim="editedItem.text" label="Template text" hide-details></v-text-field>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn text @click.stop="handleClose">
            Cancel
          </v-btn>
          <v-btn text @click.stop="submit">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const getDefaultState = () => ({
  editedItem: {
    text: ''
  }
});

export default {
  props: {
    open: Boolean,
    data: Object
  },
  data: () => getDefaultState(),
  updated() {
    if (this.$props.data) this.$data.editedItem = this.$props.data;
  },
  methods: {
    handleClose() {
      this.$emit('onClose', this.$data.editedItem);
      Object.assign(this.$data, getDefaultState());
    },
    async submit() {
      const data = this.editedItem;

      if (data.text.trim().length) {
        if (data.id) {
          await this.$store.dispatch('templates/edit', data);
          await this.$store.dispatch('templates/load');
        } else {
          await this.$store.dispatch('templates/create', data);
          await this.$store.dispatch('templates/load');
        }
        this.handleClose();
      } else {
        window.alert('Template text cannot be empty! Please, enter valid template text');
      }
    }
  }
};
</script>
