<template>
  <div>
    <div class="d-flex mb-5">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            elevation="3"
            class="mb-5 ml-3 mt-3"
            v-bind="attrs"
            v-on="on"
            @click.stop="isDialogOpen = true"
          >
            <v-icon>fa-plus</v-icon>
          </v-btn>
        </template>
        <span>Create Template</span>
      </v-tooltip>
    </div>

    <Dialog
      :data="editedItem"
      :open="isDialogOpen"
      width="500px"
      height="500px"
      @onClose="handleDialogClose"
    />

    <Table class="my-2" />
  </div>
</template>

<script>
import Table from './table.vue';
import Dialog from './dialog.vue';

export default {
  components: {
    Table,
    Dialog
  },
  data: () => ({
    isDialogOpen: false,
    editedItem: null
  }),
  created() {
    this.$store.dispatch('templates/load');
  },
  methods: {
    handleDialogClose() {
      this.$data.editedItem = null;
      this.$data.isDialogOpen = false;
    }
  }
};
</script>
